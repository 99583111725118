@import './scss/variables.scss';

html {
    background: url("./images/line_pattern.png") repeat;
    background-size: 8%;
}

.page-content-overlay {
    background-color: $white;
    position: fixed;
    left: 32px;
    right: 32px;
    top: 32px;
    bottom: 32px;
    opacity: 0.5;
}

// Navigation
.std-brand-logo {
    position: absolute;
    width: 49px;
    height: auto;
    right: 16px;
    top: 9px;
}
.nav-toggle-container {
    button {
        padding-left: 6px;
        padding-right: 6px;
    }
    .navbar-toggler {
        border: 0;
        padding: 0;
        img {
            width: 50px;
            height: auto;
        }
        .navbar-toggler-icon {
            border-color: $white;
            color: $white;
        }
    }
}

.toggle-icon {
    width: 30px;
    height: 30px;
    display: block;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)'");
}

// Map Screen
.map-container {
    display: flex;
    flex-direction: column;
}
.map {
    position: relative;
    height: 69vh;
    overflow: hidden;
}
.map-overlay {
    position: fixed;
    background: $dark_blue;
    padding: 8px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    color: $white;
    text-align: center;
    
    .location-name {
        font-weight: bold;
        font-size: 0.8em;
        margin-bottom: 0;
    }
    .map-overlay-title {
        font-size: 0.8em;
        text-transform: uppercase;
    }
    .location-text {
        font-size: 0.7em;
    }
    .location-info {
        text-align: left;
    }
    .location-image {
        width: 100%;
        height: auto;
        max-width: 100px;
    }
    .advance-btn {
        padding: 8px;
        background-color: $red !important;
        border-color: rgba(0,0,0,0.25) !important;
        margin: 0;
        padding-top: 2px;
        padding-bottom: 2px;
        text-transform: uppercase;
        font-size: 0.8em;
        margin-top: 6px;
    }
}

.image-hint-container {
    position: fixed;
    right: -32px;
    top: 14px;
    width: 70%;
    img {
        width: 100%;
        height: auto;
    }
}

.map-nav-btn {
    position: absolute;
    right: 16px;
    top: 80px;
    background-color: #eee;
    border-color: rgba(0,0,0,0.15);
    width: 50px;
    text-align: center;
    padding: 2px 0;
    img {
        width: 32px;
        height: auto;
        padding: 2px 0;
        display: block;
        margin: 0 auto;
    }
}

.map-nav-btn:active,
.map-nav-btn:visited,
.map-nav-btn:hover,
.map-nav-btn:focus {
    background-color: #aaa !important;
}

// Points
.score-container {
    position: fixed;
    top:0;
    left:0;
    margin-left: 16px;
    margin-top: 16px;
    color: $white;
    z-index: 5;
    .score-label {
        position: relative;
        font-size: 0.5em;
    }
    .score-text-container {
        position: relative;
        top: 51px;
        left: 3px;
    }
    .score-text {
        position: relative;
        font-size: 1.3em;
        line-height: 1;
    }
    .score-bg {
        position: absolute;
        width: 120px;
        left: -27px;
        top: 0;
    }
}

// Timer
.timer-container {
    position: fixed;
    top: 120px;
    left: 16px;
    color: $white;
    .timer-text-container {
        position: relative;
        top: 50px;
        left: 2px;
    }
    .timer-label {
        font-size: 0.5em;
        line-height: 1;
    }
    .timer-text {
        font-size: 1.3em;
        line-height: 1;
    }
    .timer-bg {
        position: absolute;
        width: 120px;
        left: -27px;
    }
}

.round-prep-container {
    .round-prep-content {
        width: 100%;
        height: 150px;        
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        font-size: 1.4em;
        font-weight: bold;
        padding: 0 32px;
    }
    .navbar.fixed-bottom {
        padding-bottom: 16px;
        padding-left: 16px;
    }
}

.round-question-container {
    .round-question-content {
        width: 100%;
        height: 150px;        
        position: absolute;
        top:30px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 0 32px;
        .round-question-text {
            text-align: center;
            font-size: 0.6em;
            font-weight: bold;
        }
    }
    .navbar.fixed-bottom {
        padding-bottom: 16px;
        padding-left: 16px;
    }
}

.round-answer-container {
    .round-answer-content {
        width: 100%;
        height: 150px;        
        position: absolute;
        top:30px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        padding: 0 32px;
        h3 {
            color: $teal;
        }
        .round-answer-text {
            text-align: center;
            font-size: 1.4em;
            font-weight: bold;
        }
        .round-answer-question-text {
            font-size: 0.65em;
        }
        .round-answer-question-text-label {
            text-align: left;
        }
    }
    .navbar.fixed-bottom {
        padding-bottom: 16px;
        padding-left: 16px;
    }
}

.game-over-container {
    .round-answer-content {
        width: 100%;
        height: 150px;        
        position: absolute;
        top:30px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        padding: 0 32px;
        h3 {
            color: $teal;
        }
    }
    .go-score
    .score-text-container {
        display: none;
    }
}

.branding-container {
    position: fixed;
    top: 8px;
    left: 0;
    right: 0;
    text-align: center;
    img {
        width: 55px;
        height: auto;
    }
}
.progress-comp {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    padding: 32px;
    h3 {
        font-size: 0.8em;
        text-align: center;
    }
    .progress-bar {
        min-width: 20px;
        background-color: $dark_blue;
    }
    .game-time-progress {
        text-align: right;
        font-size: 0.8em;
    }
}
.modal-comp {
    position: fixed;
    top: 25%;
    left: 0;
    right: 0;
    margin: 16px;
    background-color: $white !important;
    border: 1px solid rgba(0,0,0,0.15) !important;
    color: rgba(0,0,0,0.85) !important;
    hr {
        background-color: rgba(0,0,0,0.15);
        color: rgba(0,0,0,0.15);
        border-color: rgba(0,0,0,0.15);
    }
    button {
        border-color: rgba(0,0,0,0.85);
        color: rgba(0,0,0,0.85);
    }
}
.prep-advance-btn {
    position: fixed;
    bottom: 16px;
    right: 16px;
    max-width: 190px;
    width: 80%;
    z-index: 1040;
    background-color: $red !important;
    border-color: rgba(0,0,0,0.25) !important;
}
.answer-label {
    font-size: 0.4em;
}

.separator {
    width: 100%;
    height: 2px;
    background-color: rgba(0,0,0,0.15);
    margin-bottom: 16px;
    opacity: 0.3;
}
